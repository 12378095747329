/* global tabac_finisherAdminNonce */
import './App.css';
import Paiement from "./components/Paiement";
import SearchField from "./components/SearchField";
import {useEffect, useState} from "react";

function App() {
    const [email, setEmail] = useState('');
    const [userSessionId, setUserSession] = useState('')
    const [userThread, setUserThread] = useState('')

    const [paiement, setPaiements] = useState([]);
    const [search, setNewSearch] = useState(true);
    console.log('passage dans le fichier app');
    useEffect(() => {
        // Remplacez 'VOTRE_URL_API' par l'URL réelle de votre API pour récupérer les paiements


        const jsonData = JSON.stringify({
            session_id: userSessionId,
            thread_id: userThread,
            email: email,


        })
        fetch('https://tabacfinisher.com/wp-json/tabac_finisherADMIN/v1/tabacFinisherAnalyse', {
            method: 'POST',
            headers: {

                'X-WP-Nonce': tabac_finisherAdminNonce.nonce,

                'Content-Type': 'application/json'
            },
            body: jsonData // Pas besoin de définir le Content-Type, il est automatiquement défini avec FormData
        })
            .then(response => response.json())
            .then(data => setPaiements(data))
            .catch(error => console.error('Erreur lors de la récupération des données:', error));
    }, [userSessionId, email]);

    return (
        <div className="tf-App">
            <header className="tf-App-header">

                <div className='tf-searchTool'>
                    <SearchField onSearch={setUserSession} title={'Session'}/>
                    <SearchField onSearch={setUserThread} title={'Thread'}/>
                    <img src='/static/media/logo.1318206de1b27cb1a08f.jpg' className='tf-imgLogotfa'/>
                    <SearchField onSearch={setEmail} title={'email'}/>
                </div>
                <Paiement userSessionId={userSessionId} email={email} paiements={paiement}/>

            </header>
        </div>
    );
}

export default App;
