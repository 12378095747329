import React, {useState, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// Importer l'icône spécifique que vous souhaitez utiliser
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import '../css/Paiement.css'
/* global tabac_finisherAdminNonce */
function Paiement({userSessionId, email = '', paiements}) {
    const [plan_regenerated, setRegen] = useState(false)
    const getOriginStyle = (origin) => {
        if (!origin) {
            return {color: 'red'}; // Si origin est vide, texte en rouge
        } else if (origin.toLowerCase() === 'node') {
            return {color: 'orange'}; // Si origin contient 'node' (insensible à la casse), texte en orange
        } else if (origin.toLowerCase() === 'client') {
            return {color: 'green'}; // Si origin contient 'client' (insensible à la casse), texte en vert
        } else {
            return {color: 'white'}; // Dans les autres cas, texte en blanc
        }
    };
    const getStatusStyle = (status) => {
        if (!status) {
            return {color: 'blue'}; // Si origin est vide, texte en rouge
        } else if (status.toLowerCase() === 'reactivated') {
            return {color: 'orange'}; // Si origin contient 'node' (insensible à la casse), texte en orange
        } else if (status.toLowerCase() === 'generated') {
            return {color: 'green'}; // Si origin contient 'client' (insensible à la casse), texte en vert
        } else if (status.toLowerCase() === 'deleted') {
            return {color: 'red'}; // Si origin contient 'client' (insensible à la casse), texte en vert
        } else {
            return {color: 'white'}; // Dans les autres cas, texte en blanc
        }
    };
    const handle_regen= ()=>{
        setRegen(true);
    }
    const handle_react=()=>{
        const jsonData = JSON.stringify({
            session_id: userSessionId,



        })
        fetch('https://tabacfinisher.com/wp-json/tabac_finisherADMIN/v1/tabacFinisherreact', {
            method: 'POST',
            headers: {

                'X-WP-Nonce':tabac_finisherAdminNonce.nonce,

                'Content-Type': 'application/json'
            },
            body: jsonData // Pas besoin de définir le Content-Type, il est automatiquement défini avec FormData
        })


    }
const copyToCB =(value) =>{

        navigator.clipboard.writeText(value);
}

    return (
        <div>
            {paiements.length > 0 && paiements.map((paiement, index) => (
                <div key={index} className="paiement">
                    <div className='champcontainer1'>
                        <div className="champ" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span className='part'>ID:</span>
                            <div style={{ marginLeft: '20px' }}>{paiement.id}</div>
                            <button onClick={() => copyToCB(paiement.id)} style={{ backgroundColor: 'blue', width: '40px', height: '40px', color: 'white', padding: '10px', borderRadius: '5px', marginLeft: '10px' }}>
                                <FontAwesomeIcon icon={faCopy} style={{ margin: 'auto' }} />
                            </button>

                        </div>

                        <div className="champ" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span className='part'>Email client:</span>
                            <div style={{ marginLeft: '20px' }}>{paiement.customer_email}</div>
                            <button onClick={() => copyToCB(paiement.customer_email)} style={{ backgroundColor: 'blue', width: '40px', height: '40px', color: 'white', padding: '10px', borderRadius: '5px', marginLeft: '10px' }}>
                                <FontAwesomeIcon icon={faCopy} style={{ margin: 'auto' }} />
                            </button>

                        </div>

                        <div id='session' className="champ" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span className='part'>Session:</span>
                            <div style={{ marginLeft: '20px' }}>{paiement.session_id}</div>
                            <button onClick={() => copyToCB(paiement.session_id)} style={{ backgroundColor: 'blue', width: '40px', height: '40px', color: 'white', padding: '10px', borderRadius: '5px', marginLeft: '10px' }}>
                                <FontAwesomeIcon icon={faCopy} style={{ margin: 'auto' }} />
                            </button>

                        </div>

                        <div id='intent' className="champ" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span className='part'>ID d'intention de paiement:</span>
                            <div style={{ marginLeft: '20px' }}>{paiement.payment_intent_id}</div>
                            <button onClick={() => copyToCB(paiement.payment_intent_id)} style={{ backgroundColor: 'blue', width: '40px', height: '40px', color: 'white', padding: '10px', borderRadius: '5px', marginLeft: '10px' }}>
                                <FontAwesomeIcon icon={faCopy} style={{ margin: 'auto' }} />
                            </button>

                        </div>

                        <div id='thread' className="champ" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <span className='part'>Thread:</span>
                            <div style={{ marginLeft: '20px' }}>{paiement.thread}</div>
                            <button onClick={() => copyToCB(paiement.thread)} style={{ backgroundColor: 'blue', width: '40px', height: '40px', color: 'white', padding: '10px', borderRadius: '5px', marginLeft: '10px' }}>
                                <FontAwesomeIcon icon={faCopy} style={{ margin: 'auto' }} />
                            </button>

                        </div>

                    </div>
                    <div className='champcontainer'>
                        <div className="champ">Statut de paiement: {paiement.payment_status}</div>
                        <div className="champ">Montant: {paiement.amount} {paiement.currency}</div>
                        <div className="champ">Créé le: {paiement.created_at}</div>

                    </div>
                    <div className='champcontainerB'>
                        <div className="champ" style={getStatusStyle(paiement.status_plan)}>Statut du plan: {paiement.status_plan}</div>
                        <div className="champ">Date du plan: {paiement.date_plan}</div>
                        <div className="champ" style={getOriginStyle(paiement.origin)}>Origine: {paiement.origin}</div>
                        <div className="champ">Retard planifié: {paiement.retardPlan ?'Non': 'Oui' }</div>
                    </div>


                    <div className='champcontainerB'>
                        <div className='champLiner'>
                            <div className="champB">Token entrant: {paiement.token_input}</div>
                            <div className="champB">Token sortant: {paiement.token_output}</div>
                            <div className="champB">Total tokens: {paiement.token_total}</div>
                        </div>
                        <div className='champLiner'>
                            <div className="champB">Cout entrant: {paiement.token_input/1000 * paiement.cost_token_input}</div>
                            <div className="champB">Cout sortant: {paiement.token_output/1000 * paiement.cost_token_output}</div>
                            <div className="champB">Cout Total:  {paiement.token_output/1000 * paiement.cost_token_output + paiement.token_input/1000 * paiement.cost_token_input}</div>
                        </div>
                    </div>
                    <div className='tools'>
                        <button className=' btnTool btnTool_REMB' >REMBOURSSER</button>
                       <button className=' btnTool btnTool_REACT' onClick={handle_react}>REACTIVER</button>
                        {paiement.status_plan ==='generated'&& <button className=' btnTool btnTool_GETPLAN' onClick={handle_regen}>REGENERER</button>}
                        {paiement.status_plan ==='generated'&& plan_regenerated && <button className=' btnTool btnTool_SEND'>ENVOYER</button>}


                    </div>

                </div>
            ))}


        </div>
    );

}

export default Paiement;
