import React, { useState } from 'react';
import  '../css/SearchField.css'
function SearchField({ onSearch,title }) {
    const [inputValue, setInputValue] = useState('');

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleSubmit = () => {
        // Ici, vous pouvez appeler la fonction onSearch passée en props avec la valeur actuelle
        if(inputValue!==''){
        onSearch(inputValue);
        }
    };

    return (
        <div className="search-field">
            <label htmlFor="searchInput">Recherche par {title}:</label>
            <input
                id="searchInput"
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                placeholder="Entrez un terme de recherche"
                className='txtWhite'
            />
            <button className='BtnSearch' onClick={handleSubmit}>Chercher</button>
        </div>
    );
}

export default SearchField;
